@font-face {
    font-family: 'icomoon';
    src: url('./fonts/icomoon.eot?y7t0ch');
    src: url('./fonts/icomoon.eot?y7t0ch#iefix') format('embedded-opentype'), url('./fonts/icomoon.ttf?y7t0ch') format('truetype'), url('./fonts/icomoon.woff?y7t0ch') format('woff'), url('./fonts/icomoon.svg?y7t0ch#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="qunifi-icon-"], [class*=" qunifi-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.qunifi-icon-microsoft-teams:before {
    content: "\e900";
}

.app-error {
    color: red;
}

/**
 * LOADING PAGE
*/
.loading-wrapper {
    text-align: center;
    margin: 40px 0;
}

.account-selector-wrapper {
    margin-top: 20px;
}

.account-selection--single {
    padding-top: 30px;
    color: black;
    display: inline-block;
}

.navbar {
    background: white;
    border-bottom: 1px solid #E1E5EA;
    padding: 5px; 
    position: sticky;
    top:0;
    left:0;
    z-index: 1035;
}
.accountBreadcrumbs {
    border-top: 1px solid #E1E5EA;
    display: flex;
    align-items: center;
}
.accountBreadcrumbs .container {
    padding-left: 0;
    display: flex;
    flex-wrap: nowrap;
}

.container--header {
    display: flex;
    align-items: center;
}

    .container--header > .navbar-collapse {
        flex-grow: 1;
    }

@media (max-width: 767px) {
    .container--header {
        display: inherit !important;
    }
}

@media (max-width: 350) {
    .container--header {
        width: 85vh !important;
        float: left !important;
    }
}

.logo-img {
    float: left;
    max-width: 180px;
    max-height: 55px;
}

.logo-text {
    display: inline-block;
    padding-top: 20px;
    padding-left: 10px;
    font-weight: bold;
    font-size: 24px;
}

.navbar-brand {
    float: left;
    height: auto;
    padding: 0px;
    margin-right: 15px;
}

    .navbar-brand span {
        color: #9d9d9d;
    }

.navbar .navbar-nav > li > a:hover, .navbar .navbar-nav > li > a:focus,
.navbar .navbar-brand:hover, .navbar .navbar-brand:focus {
    color: black;
}

.navbar .navbar-nav > li > a,
.navbar .btn-link {
    color: var(--NEU-Cold-grey-Black-prim-LM, #000);
}

@media (min-width: 768px) {
    .navbar-nav > li > a {
        padding-top: 30px;
    }
}

.navbar-text {
    margin-top: 30px;
}

.main-nav li a {
    /* border-bottom: 2px solid transparent; */
    padding-top: 18px;
    padding-bottom: 14px;

    color: var(--NEU-Cold-grey-Black-prim-LM, #000);
    /* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

    /* .main-nav li a.current,
    .main-nav li:hover a {
        border-bottom: 2px solid var(--NEU-Cold-grey-Black-prim-LM, #000);
    } */

    .main-nav li a.current {
        font-weight: bold;
    }
/**
 *  HELPERS
 */
.fa-times {
    color: maroon;
}

.fa-times--grey {
    color: grey;
}

.fa-check {
    color: lightgreen;
}

.circle-ok {
    color: lightgreen;
}

.circle-na {
    color: lightblue;
}

.circle-warn {
    color: #ffbf00;
}

.pending {
    color: #aaaaaa;
}

.circle-problem {
    color: red;
}

.circle-unused {
    color: grey;
}

.fa-exclamation-triangle,
.fa-check--warn,
.fa-exclamation-circle {
    color: #ffbf00;
}
/**
 * Loading spinner
*/
.loading-wrapper.fixed-right {
    right: 0;
    -webkit-transform: translateX(50%) translateY(-50%) rotate(45deg);
    -moz-transform: translateX(50%) translateY(-50%) rotate(45deg);
    -ms-transform: translateX(50%) translateY(-50%) rotate(45deg);
    -o-transform: translateX(50%) translateY(-50%) rotate(45deg);
    transform: translateX(50%) translateY(-50%) rotate(45deg);
}

.loading-wrapper {
    position: fixed;
    z-index: 2000;
    top: 0;
    width: 200px;
    height: 200px;
    background: #aed13d; /* original blue: #22a5ff; */
    box-shadow: 0px 0px 2px 0px #303039;
    /* default off, let JS control when to show this... */
    display: none;
}

.big-spinner {
    font-size: 3em;
    left: 80px;
    position: absolute;
    bottom: 20px;
    color: black;
}


/**
 * FORM ELEMENTS
 */
.loading-spinner {
    display: none;
}

.user-form label {
    /* push down due to table markup */
    padding-top: 7px;
}

/* New domain, user form */
.user-form-wrapper,
.domain-form-wrapper {
    margin-top: 20px;
}

.user-form-wrapper--admin p {
    margin-bottom: 20px;
    max-width: 75%;
}

.new-admin-invite-form {
    margin-bottom: 0;
}

.action-row {
    margin-bottom: 20px;
}

label.error {
    font-weight: normal;
    display: block;
    color: red;
}


/**
 * TABLE ELEMENTS
 */
td.header-full-row-cell {
    padding-top: 12px !important;
    border-top: 0 !important;
}

.header-icon {
    vertical-align: middle;
}

tr.shown td,
.clickable-row:hover td {
    background: #e6e6e6 !important;
}

.expanded-action {
    display: none;
    margin-top: 10px;
}

.shown .expanded-action {
    display: block;
}

/** bump size of icons in activate/deactivate buttons */
.expanded-action .btn i {
    font-size: 1.2em;
}

/* Popover arrow isn't reliable in our test table case, so turn it off */
table .popover .arrow {
    display: none;
}

.clickable-row {
    cursor: pointer;
}

.child-row-content {
    padding-left: 15px;
    border-left: 5px solid #e6e6e6;
}

    .child-row-content table tr,
    .child-row-content table {
        background-color: transparent;
    }

.header-icon {
    font-size: 1.5em;
}

.teams-col, .sfb-col {
    text-align: left;
}

.platform-checklist .form-check {
    padding: 0 15px;
    font-size: 1.2em;
}

.lower-link-domain-form {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 20px;
}

.form-row {
    margin-bottom: 20px;
}

.right-action-button {
    margin-left: 10px;
    float: right;
}

/**
 * USER PAGE
*/
.nav--user-platform-switch {
    margin-bottom: 30px;
}

.federated-domain {
    padding: 5px;
    display: inline-block;
}

/**
 * Expandable sections
*/
.panel-group .panel-heading {
    padding: 0;
}

    .panel-group .panel-heading a {
        display: block;
        padding: 15px;
    }

    .panel-group .panel-heading:hover {
        background: #e6e6e6;
    }

.expand-icon {
    float: right;
}

.expand-icon--open {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}

.expand-icon--closed,
.collapsed .expand-icon--open {
    display: none;
}

.collapsed .expand-icon--closed,
.expand-icon--open {
    display: block;
}


/**
 * BODY CLASSES for BUTTON TRIGGERS
*/
.no-teams-domain .add-teams-user {
    display: none;
}

.add-teams-user--domain-info {
    display: none;
}

.no-teams-domain .add-teams-user--domain-info {
    display: block;
}


.footer {
    text-align: center;
    width: 100%;
    padding: 20px;
    margin: 60px auto 0;
    border-top: 2px solid #efefef;
    color: #aba9a9;
}

    .footer p {
        font-size: 0.9em;
    }

.main-flex {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

    .main-flex > div {
        flex: 0 1 auto;
    }

        .main-flex > div.container--main-content {
            flex: 1;
        }


/**
 * semi-temp view improvers...
 */
.navbar .dropdown {
    margin-top: 11px;
}

li.username {
    padding: 5px 0 10px;
}

.dropdown-menu {
    padding-top: 15px;
    padding-bottom: 0px;
}

    .dropdown-menu li {
        padding-left: 15px;
        padding-right: 15px;
    }

hr.small {
    margin-top: 10px;
    margin-bottom: 10px;
}
hr.dark-hr {
    background: black;
    border-color: #777;
}
.sip-error-detail h4 {
    margin-top: 20px;
}

.dropdown-menu li.profile-menu-action {
    padding-left: 0;
    padding-right: 0;
}

.dropdown-menu .title {
    font-weight: bold;
}

.baseAccountSelectWrapper button {
    border: none;
    width: 100%;
    background: transparent;
    text-align: left;
    padding: 6px 0;
    display: inline-block;
}

.baseAccountSelectWrapper.currentId,
.baseAccountSelectWrapper:hover {
    background: #EEE;
}

.profile-menu {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

    .profile-menu .btn-default {
        font-weight: bold;
        margin-left: 0;
    }

.profile-menu--list {
    max-height: 50vh;
    overflow-y: auto; /* show scroll when needed */
}

.header-info {
    float: right;
    font-size: 80%;
}
.form-new-teams-service .form-group{
    text-align: left;
}
.form-new-teams-service.form-is-submitting .btn,
.form-new-teams-service.form-is-submitting {
    cursor: wait;
}

.document-list {
    list-style-position: inside;
    margin-left: 15px;
    padding-left: 2px;
}

    .document-list i {
        padding-right: 10px;
    }
/* strong */ .document-list-title {
    display: block;
    margin-bottom: 10px;
}

/* ensure pbx template dropdown is over country */
.select-dropdown--pbx {
    z-index: 51;
}

.btn-link--faux {
    border: 0;
    padding: 0;
    display: inline;
    font-size: 1em;
    padding: 0;
    padding-bottom: 3px;
}

.btn-link--xs {
    padding: 0;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 1em;
}

.new-order-wrapper {
    margin-bottom: 20px;
}

.user-form-action-buttons--top {
    margin: 20px 0;
}

.quantity-field {
    margin: 0 0 0 auto;
    width: 100px;
    text-align: right;
}

.state-label {
    padding-top: 5px;
}

.currency-code-col {
    width: 10%;
}

.table--contract .currency-code-col .form-control {
    width: 70px;
}

.download-bill-col button {
    margin-left: 8px;
}

.form-number-range-wrapper .form-control {
    width: 95%;
}

.reg-status-small-header {
    margin-left: 15px;
}

.sync-button-wrapper p {
    margin-bottom: 5px;
}

.sync-messages {
    padding-top: 5px;
}

    .sync-messages span {
        margin-right: 5px;
    }

.sync-markdown-wrapper table {
    margin-bottom: 25px;
    table-layout: fixed;
}

    .sync-markdown-wrapper table th,
    .sync-markdown-wrapper table td {
        padding: 5px;
    }
    /* Ensure fixed width columns gets ellipsis, not wrapped */
    .sync-markdown-wrapper table tr td {
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .sync-markdown-wrapper table th {
        background: #EEE;
    }

.sync-markdown-wrapper pre code {
    white-space: unset;
}

.text-normal {
    color: #111;
}

.text-notice, .text-notice * {
    color: blue;
}

    .text-notice .btn-link {
        text-decoration: underline;
    }

.text-error {
    color: red;
}

.unavailable-service-tab {
    color: #BBB;
    font-style: italic;
}

/* Ensure any freshworks widget used goes via our own button */
#launcher-frame {
    display: none !important;
}

.form-group--with-central {
    display: flex;
}

.central-button-wrapper {
    text-align: center;
    display: inline-block;
    flex-grow: 1;
}

.pulsingButton > div {
    box-shadow: 0 0 0 0 rgba(0, 120, 212, 5);
    -webkit-animation: pulsing 1.25s 1s infinite forwards cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulsing 1.25s 1s infinite forwards cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulsing 1.25s 1s infinite forwards cubic-bezier(0.66, 0, 0, 1);
    animation: pulsing 1.25s 1s infinite forwards cubic-bezier(0.66, 0, 0, 1);
    transition: all 300ms ease-in-out;
}

    .pulsingButton > div:hover {
        -webkit-animation: none;
        -moz-animation: none;
        -ms-animation: none;
        animation: none;
    }

.pulsingButtonFail > div {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 5);
    -webkit-animation: pulsingFail 1.25s 1s infinite forwards cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulsingFail 1.25s 1s infinite forwards cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulsingFail 1.25s 1s infinite forwards cubic-bezier(0.66, 0, 0, 1);
    animation: pulsingFail 1.25s 1s infinite forwards cubic-bezier(0.66, 0, 0, 1);
    transition: all 300ms ease-in-out;
}

    .pulsingButtonFail > div:hover {
        -webkit-animation: none;
        -moz-animation: none;
        -ms-animation: none;
        animation: none;
    }

@-webkit-keyframes pulsing {
    to {
        box-shadow: 0 0 0 5px rgba(0, 120, 212, 0);
    }
}

@-moz-keyframes pulsing {
    to {
        box-shadow: 0 0 0 5px rgba(0, 120, 212, 0);
    }
}

@-ms-keyframes pulsing {
    to {
        box-shadow: 0 0 0 5px rgba(0, 120, 212, 0);
    }
}

@keyframes pulsing {
    to {
        box-shadow: 0 0 0 5px rgba(0, 120, 212, 0);
    }
}

@-webkit-keyframes pulsingFail {
    to {
        box-shadow: 0 0 0 5px rgba(255, 0, 0, 0);
    }
}

@-moz-keyframes pulsingFail {
    to {
        box-shadow: 0 0 0 5px rgba(255, 0, 0, 0);
    }
}

@-ms-keyframes pulsingFail {
    to {
        box-shadow: 0 0 0 5px rgba(255, 0, 0, 0);
    }
}

@keyframes pulsingFail {
    to {
        box-shadow: 0 0 0 5px rgba(255, 0, 0, 0);
    }
}

.allow-uri-break {
    word-break: break-all;
}

.arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 6px;
}

.arrow-right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.diagram-line-icon {
    position: absolute;
    top: -8px;
    right: 0.35%;
    /* when three columns in CallFlowDiagram.js right: 0.5%;*/
}

.diagram-line-left {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.diagram-line-right {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
}

/*bootstrap 4/5 utility classes */
.thin-gutter {
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.no-gutter {
    padding-left: 0px;
    padding-right: 0px;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.border-warning {
    border-color: #ffc107 !important;
}

.border-danger {
    border-color: #dc3545 !important;
}

.border-success {
    border-color: #198754 !important;
}

.border-top {
    border-top: 1px solid #dee2e6;
}

.border-start {
    border-left: 1px solid #dee2e6;
}

.border-end {
    border-right: 1px solid #dee2e6;
}

.border-2 {
    border-width: 2px !important;
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.m-0 {
    margin: 0 !important;
}

.m-2 {
    margin: .5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-2 {
    margin-top: .5rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.mb-1 {
    margin-bottom: .25rem !important;
}

.ms-2 {
    margin-left: .5rem !important;
}

.me-2 {
    margin-right: .5rem !important;
}

.ms-5 {
    margin-left: 3rem !important;
}

.me-5 {
    margin-right: 3rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.text-muted {
    color: #6c757d !important;
}

.text-primary {
    color: #0d6efd !important;
}

.fw-bolder {
    font-weight: bolder !important;
}

.fw-normal {
    font-weight: 400 !important;
}

.d-none {
    display: none !important;
}

@media (min-width: 768px) {
    .row.equal {
        display: flex;
        flex-wrap: wrap;
    }
}

@media (min-width: 576px) {
    .d-sm-block {
        display: block !important;
    }
}

@media (min-width: 992px) {
    .d-lg-block {
        display: block !important;
    }
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }
} 

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50% !important;
}
.w-25 {
    width: 25% !important;
}
.w-75 {
    width: 75% !important;
}

.default {
    cursor: default;
}

.shopping-link, .support-link  {
    display: inline-block;
    position: relative;
    top: -17px;
}
.support-link {
    margin-right: 5px;
}
.profile-menu-btn {
    margin-top: 5px;
}
.displayName {
    color: var(--NEU-Cold-grey-Black-prim-LM, #000);
    /* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}
.account-Name {
    color: var(--NEU-Cold-grey-600-ter-LM, #475467);
    /* font-family: Inter; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    max-width:150px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.kazoo_auth {
    width: 100vw;
    height: 100vh;
    padding: 0;
    position: absolute;
    top:0;
    left:0;
    background-color: #202029;
    font-family:'Source Sans Pro', Helvetica, sans-serif;
    z-index:-2;
}
.kazoo_auth input {
    z-index:0;
}
.kazoo_auth div.form-input-wrapper, div.form-input-group-wrapper {
    position: relative;
    height: 40px;
    width: 100%;
    margin: 25px 0;
    background-color: transparent !important;
}

.kazoo_auth div.form-input-group-wrapper {
    line-height: 45px; 
    border-bottom: solid 1px #808089;
}

.kazoo_auth div.content > h1 {
    text-align: center;
    color: #ffffff;
    margin-bottom:50px;
}

.kazoo_auth div.content {
    max-width: 400px;
    margin: auto;
}

.kazoo_auth div.form-input-wrapper > input {
    outline: none;
    width: 100%;
    font-size: 15px;
    border: none;
    border-bottom: solid 1px #808089;
    background-color: transparent !important;
    color: #ffffff;
    height: 40px;
    letter-spacing: 0.025em;
    padding: 0;
    line-height:45px;
}

.kazoo_auth div.form-input-group-wrapper > label {
    outline: none;
    width: 25%;
    font-size: 15px;
    border: none;
    background-color: transparent !important;
    color: #909099;
    letter-spacing: 0.025em;
    padding: 0 5px;
}

.kazoo_auth .input-active {
    border-bottom: solid 2px #22a5ff !important;
}

.kazoo_auth .radio-label {
    float: left;
    width: 50%;
    font-size: 14px;
    color: #909099;
    z-index: -1;
}

.kazoo_auth .placeholder-shift {
    position: absolute;
    top: 16px;
    left: 0;
    font-size: 14px;
    color: #909099;
    transition: all 0.2s ease-out;
    z-index:-1;
}

.kazoo_auth .placeholder-shift-fixed {
    top: -12px !important;
    font-size: 12px;
    letter-spacing: 0.02em;
}

.kazoo_auth .placeholder-shift-active {
    color: #22a5ff;
}

.kazoo_auth .placeholder-shift-completed {
    color: #909099;
}

.kazoo_auth div.form-signin-wrapper > button {
    width: 100%;
    color: #ffffff;
    background:#22a5ff;
    border-radius: 2px;
    width:100%;
    height:50px;
    font-size:20px;
    border:none;
    transition: all 0.2s ease-in;
}

.kazoo_auth div.form-signin-wrapper > input:hover {
    cursor: pointer;
    background: #57bcff;
}

.kazoo_auth input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #202029 inset !important;
    -webkit-text-fill-color: #e0e0e9 !important;
}