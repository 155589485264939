/* Use MS fabric font */
* {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

/* div */.link-set {
    margin-bottom: 30px;
}

.domainForm-table-cell .domain-form-wrapper {
    margin: 0;
}

/* react table */
.rt-th {
    font-weight: bold;
}
body .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    padding: 5px;
    border-bottom: 2px solid #ddd;
}
body .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    border-right: none;
}
body .ReactTable .rt-thead .rt-td, .ReactTable .rt-thead .rt-th {
    border: none;
}
body .ReactTable .-pagination {
    border-top: 1px black solid;
    padding-top: 15px;
}
body .ReactTable {
    border: none;
}

.col-status-light {
    text-align: center;
}
.col-status-light .q-tooltip {
    display: none;
}
.col-status-light:hover .q-tooltip {
    display: block;
}
.col-status-light:hover .fa-circle {
    text-shadow: 0px 0px 7px #333;
}
.util-inline-block {
    display: inline-block;
}
.trunk-header-status {
    padding-right: 15px;
}

/* wrapper for on-hover tooltip */
.q-popup-status-wrapper {
    width: 100%;
    position: relative;
    overflow: visible;
    font-size: 14px;
    font-weight: normal;
    color: black;
    line-height: 20px;
}
.q-tooltip h5 {
    text-align: center;
    font-weight: bold;
    color: #222;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 2px solid #EEE;
}
.q-tooltip p {
    margin-bottom: 5px;
    white-space: normal;
}
.q-tooltip {
    background: white;
    border: 1px solid #ddd;
    padding: 0;
    position: absolute;
    width: 300px;
    bottom: 100%;
    /** center **/
    left: 50%;
    margin-left: -150px;
    word-break: break-all;
    z-index:1;
}
.q-tooltip--call-summary {
    padding: 10px;
}

/* allow some overflow so tooltips work... */
body .ReactTable .rt-table,
body .ReactTable .rt-tbody {
    overflow: visible;
}
body .ReactTable .rt-td.col-has-tooltip {
    overflow: visible;
}

body .ReactTable .rt-noData {
    width: 100%;
    background: #eee;
    text-align: center;
    color: #222;
    top: auto;
    bottom: 0;
    left: 0;
    -webkit-transform: none;
    transform: none;
    padding: 10px;
    z-index: 0;
}

.ReactTable .rt-thead.-header {
    box-shadow: none !important;
}
.ReactTable .-pagination {
    box-shadow: none !important;
}
.ReactTable .-pagination .-btn{
    width: 25% !important;
    margin: 0 auto;
}
#root .ReactTable .rt-thead .rt-tr,  .call-table-wrapper .ReactTable .rt-thead .rt-tr{
    text-align: left;
}
    .user-form-action-buttons {
    padding: 0;
}
.admin-user-form-action-buttons {
    padding-top:10px;
}

.select-region {
    margin-left: 20px;
}


.fa-spin-2x {
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
}

.user-form-action-buttons--top {
    margin-top: 0;
    padding-top: 0;
}
.update-domain-form th {
    width: 16%;
}
.update-domain-form td {
    width: 34%;
}

.user-service-selector {
    max-width: 300px;
    display: inline-block !important;
}
.preview-reg-messages {
    list-style: none;
    white-space: normal;
    padding: 5px;
    text-align: left;
    margin-bottom: 0;
    word-break: break-word;
}

.profile-menu-wrapper .dropdown {
   display: inline-block; 
}
.profile-menu-wrapper .btn {
    text-align: left;
}
.btn-default {
    background: none;
    border: none !important;
}
.btn--full-length {
    width: 100%;
}
.btn--pbx-custom-shortcut {
    background: transparent;
    margin: 10px 0;
    color: grey;
    text-decoration: underline;
}
.btn--multi-line-text {
    text-align: left;
    white-space: normal;
    word-break: break-word;
}

/** Embedded Video */
.video-wrapper {
    padding-top: 56.25%;
    position: relative;
}
.video-iframe {
    height:100%;
    left:0;
    position:absolute;
    top:0;
    width:100%;
}

ul.tick-list {
    list-style: none;
    padding-left: 10px;
}

ul.tick-list li:before {
    content: '✓';
    padding-right: 10px;
    margin-left: 0;
    color: limegreen;
    font-weight: bold;
}

.alert p.force-p-margin {
    margin-bottom: 15px;
}

.greyed-out {
    color: #bbb;
}

.inner-table-wrapper,
.domain-inner-table-wrapper, .ranges-table-wrapper {
    padding: 20px;
}
.element-content--domain-inner {
    padding: 20px;
    /* display: block;  needed? */
}
.domain-detail-wrapper {
    padding: 20px;
}

ol.breadcrumb {
    margin-bottom: 0;
}
.breadcrumbs-wrapper{
    white-space: nowrap;
}
.breadcrumbs-wrapper,
.breadcrumbs {
    display: inline-block;
}
.breadcrumbs {
    width: 100%;
}
.breadcrumbs ol {
    background: none;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
}
.search-crumbs .breadcrumbs ol {
    flex-wrap: wrap;
    white-space: break-spaces;
}
.search-crumbs .breadcrumbs ol li {
    width: 100%;
}
.breadcrumb-item {
    font-size: 85%;
    flex: 0 0 auto;
    overflow: hidden;
}
.breadcrumb-item.active .accountName {
    position: relative;
    color: var(--NEU-Cold-grey-600-ter-LM, #475467);
    /* font-family: Inter; */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}
.breadcrumbs-wrapper .btn-link {
    margin-left: 0;
    padding: 6px 3px;
    color: var(--NEU-Cold-grey-600-ter-LM, #475467);
    /* font-family: Inter; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    height: 35px;
    text-align: start;
}
.breadcrumbs-wrapper .navbar-inverse .btn-link:hover {
    color: black;
}
.hiddenBreadcrumbsMenu{
    position:absolute;
    background-color: white;
    z-index: 1000;
    min-width: 150px;
    max-width: 250px;
    display: block;
    top: auto;
    left: auto;
    float: none;
    padding: 15px 0;
}
.hiddenBreadcrumbsMenu ul {
    list-style: none;
    padding-left: 0;
}
.hiddenBreadcrumbsMenu li:hover {
    background-color: #EEE;
}
.search-crumbs .breadcrumbs ol {
    padding: 0;
}
.search-crumbs .breadcrumbs .breadcrumb-item {
    font-size: 100%;
}

.btn--domain-delete {
    margin-left: 10px;
}

.Portal .react-tabs__tab {
    padding: 8px 18px;
}
.Portal .react-tabs__tab--selected {
    border-color: #ddd;
}
.Portal .react-tabs__tab-list {
    border-color: #ddd;
}
.Portal .react-tabs__tab {
    padding: 12px 18px;
}
.Portal .react-tabs__tab-panel {
    padding: 20px 0;
}
.form-new-service-form {
    max-width: 500px;
}
.form-new-pbx-service .btn-link {
    padding: 0;
}

.form-templates .btn-link {
    padding: 0 0 0 2px;
    outline:none !important;
    margin: -3px 0 0 0;
    line-height:1;
}

.form-control-height {
    min-height: 34px;
}

.form-templates .form-control,
.form-company-external-links .form-control {
    min-height: 38px !important;
}

.btn--service-form {
    margin-left: 10px;
    text-align: right;
}
.btn--remove-domain-inline {
    margin-left: 10px;
}
.service-domain-form-listing {
    margin-bottom: 15px;
}

.table-dns-records .btn-link {
    padding: 0;
}

.ms-style-form input {
    border-radius: 0;
}
.error-message {
    color: red;
}
.service-col div.col-md-6 {
padding-top: 20px;
padding-bottom: 20px;
}
.service-th {
    background: #eee;
    font-weight: bold;
}

/**
 * Bootstrap to fabric UI helpers
 */
.css-yk16xz-control,
.btn, select, input, button {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}
.btn-primary {
    border-color: rgb(0, 120, 212) !important;
    background-color: rgb(0, 120, 212) !important;
}
.btn-primary:hover {
    border-color:  rgb(16, 110, 190) !important;
    background-color:  rgb(16, 110, 190) !important;
}

/* service information flex table */
.service-part-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: stretch;
}
.service-col {
    flex: none;
    width: 50%;
}
.service-th, .service-td {
    padding: 5px;
}

/* action header, flex to align baseline */
.action-header {
    display: flex;
    flex-direction: row;
    flex-wrap: none;
    width: 100%;
    align-items: center;
}
.action-header-text {
    flex: 1 1 auto;
}
.action-header-actions {
    flex: none;
    text-align: right;
}
/* sync button */
.action-header-actions > div {
    display: inline-block;
}

.user-form-parts-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: stretch;
}
.user-form-parts-wrapper .form-group {
    width: 50%;
    padding-right: 10px;
}
.form-group-full {
    width: 100%;
    padding-right: 10px;
}
.form-parts-tight .form-group {
    width: 25%;
}
.form-padding {
    padding: 15px;
}
.px-3 {
    padding: 0 15px;
}
.group-controls--container {
    width: 100%;
    display:flex;
}


.service-line {
    border-bottom: 1px solid #eee;
    padding: 10px 0px 10px 0px;
}
.service-line h4 {
    margin-bottom: 0;
}
.service-line span {
    font-weight: bold;
    display: inline-block;
}
.service-line select {
    display: inline-block;
    width: auto;
}
.user-form-parts-wrapper {
    margin-bottom: 25px;
}
.update-api-key-form .user-form-parts-wrapper {
    margin-bottom: 0;
}
.user-form-parts-wrapper--pbx {
    margin-bottom: 0;
}
.btn-default-extra-space {
    margin-left: 20px !important;
}
.btn-default-tight {
    margin-left: 0 !important;
}
.unattached-span {
    font-style: italic;
}

.code-output-limit {
    max-height: 500px;
    overflow-y: auto;
}
.dns-wrapper {
    margin-bottom: 30px;
}

.ms-Button.ms-Button--danger,
.ms-Button.ms-Button--danger + .ms-Button {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
}
.ms-Button.ms-Button--danger:active,
.ms-Button.ms-Button--danger:hover,
.ms-Button.ms-Button--danger:active + .ms-Button,
.ms-Button.ms-Button--danger:hover + .ms-Button {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925;
}
.ms-Button.ms-Button--danger:focus,
.ms-Button.ms-Button--danger:focus + .ms-Button {
    color: #fff;
    background-color: #c9302c;
    border-color: #761c19;
}

.button-header,
.button-header-with-help span,
.button-header-with-help h4 {
    cursor: pointer;
}
.button-header-with-help span,
.button-header span {
    font-size: 14px
}
.button-header-with-help span {
margin-left: 20px;
}

.button-header-with-help h4,
.button-header h4 {
    display: inline-block;
    margin-bottom: 0;
}
.button-header--open h4 {
    margin-bottom: 15px;
}
.button-header-with-help h4,
.button-header-with-help span,
.button-header h4,
.button-header span {
    border-bottom: 1px solid transparent;
}
.button-header-with-help h4:hover,
.button-header-with-help span:hover,
.button-header:hover h4,
.button-header:hover span {
    border-bottom: 1px solid #222;
}

.clickable:hover {
    text-decoration: underline;
}
.clickable {
    cursor: pointer;
}
.underline-text {
    text-decoration: underline;
}
.call-table-wrapper {
    width: 900px;
    padding: 20px;
}
.problem-call-table-wrapper {
    width: 100%;
    max-width: 900px;
    min-width: 600px;
    padding: 20px;
}
.account-expand-wrapper {
    padding: 10px;
}
.ms-advanced-option-dropdown {
    padding: 20px;
    border-left: 1px solid #aaa;
}

.system-owner-bg {
     background:rgba(241,196,15, 0.3);
}
.system-owner-wrapper {
     background:rgba(241,196,15, 0.3);
     padding: 20px 20px 0;
 }
.system-owner-action {
    background: rgba(241,196,15, 0.3);
}
.system-owner-wrapper .table>tbody>tr>td,
.system-owner-wrapper .table>tbody>tr>th,
.system-owner-wrapper .table>tfoot>tr>td,
.system-owner-wrapper .table>tfoot>tr>th,
.system-owner-wrapper .table>thead>tr>td,
.system-owner-wrapper .table>thead>tr>th {
    border-color: #ccc;
}
.system-owner-wrapper hr {
    border-color: #ccc;
}

.form-group--phone-number .is-disabled .ms-TextField-prefix {
    padding-right: 0;
}
.form-group--phone-number .is-disabled input {
    padding-left: 5px;
}
.long-header-string {
    word-break: break-all;
}
.no-call-data-light {
    color: #fff !important;
    text-shadow: 0px 0px 1px #000;
}
.call-in-progress {
    animation: callpulse 1s infinite;
}
@keyframes callpulse {
    0% {
        text-shadow: 0px 0px 2px #000;
    }
    100% {
        text-shadow: 0px 0px 2px #ccc;
    }
}

.large-debug-text {
    width: 100%;
    max-width: 100%;
    overflow: auto;
    white-space: pre-wrap;
}

.capitalize {
    text-transform: capitalize;
}

.hover-span {
    cursor:default;
    padding: 5px;
    display: inline-block;
}
.hover-span:hover {
    color: black;
}
.form-control--ms-dns {
    max-width: 200px;
}

.user-form-parts-wrapper .form-group.form-group-e164to,
.user-form-parts-wrapper .form-group.form-group-e164from {
    width: 25%;
}

.btn-sbc-help {
    margin-left: 10px;
}
span.sbc-list-space {
    margin-right: 5px;
}

/** Handle large number of SBCs **/
.btn--sbc-ui-list {
    text-align: left;
    white-space: normal;
}

.audit-order-item-row strike {
    margin-right: 10px;

}
.order-item-wrapper {
    margin: 20px 0;
}
.order-item-QuantityChange td,
.order-item-StateChange td {
    color: #AAA;
}
.order-item-QuantityChange td.has-change,
.order-item-StateChange td.has-change {
    color: black;
}

.form-group.form-group--full,
.form-group-CallingPolicy:first-of-type {
    width: 100%;
}
/*label*/.no-bold {
    font-weight: normal;
}
.label-spacing  .select-dropdown{
    padding-top: 1rem;
}
label .select-dropdown, label, input {
    font-weight: 400;
}
.account-search-form {
    float: right;
    display: inline-block;
}
.search-results-wrapper {
    width: 700px;
    padding: 20px;
}

.codec-input-wrapper {
    width: 100%;
}
.form-group.form-group-1-2 {
    width: 50%;
}
.form-group.form-group-3-4 {
    width: 75%;
}
.error input, .error select {
    border: 1px solid red;
}

.wizard-breadcrumbs-container {
    margin: 1em 0 1em 0;
}

.wizard-breadcrumb {
    font-size: 18px;
    text-align: center;
    padding: 0.25em;
}

.wizard-breadcrumb--active {
    color: #9d9d9d;
    border-bottom: solid 3px #4284f5;
}

.wizard-breadcrumb--completed {
    color: #9d9d9d;
    border-bottom: solid 3px #aac354;
}

.wizard-button--container {
    margin: 2em 0 5em 0;
}

.wizard-breadcrumb--uncompleted {
    color: #cccccc;
    border-bottom: solid 3px #d3d3d3;
}

.text-align--center {
    text-align: center;
}

.prerequisite-status {
    color: #cccccc;
}

.margin-left--0 {
    margin-left: 0px !important;
}

.wizard-btn-primary {
    min-width: 18em;
}

.wizard-btn-primary:disabled {
    color: dimgrey !important;
    background-color: lightgray !important;
    opacity: 0.5 !important;
    border-color: lightgray !important;
}

div.prerequisite-status-icon--container {
    text-align: center;
    font-size: 1em;
}

.prerequisite-status-icon--success {
    color: #6bb700;
}

.prerequisite-status-icon--failure {
    color: #dc3545;
}

.prerequisite-status-icon--warning {
    color: #ffc107;
}

.prerequisite-dailog {
    background-color: #ffffff;
    display: flex;
    align-items: center;
}

.ms-Spinner-label {
    color: #000000 !important;
}
/* override the bad lib breaking the toggle display */
.call-diag-wrapper {
    display: flex !important;
    align-items:center;
    margin-bottom:8px;
}
.call-diag-wrapper p {
    flex-grow: 1;
    padding: 0 10px 0 0;
    margin:0;
}

.call-diag-wrapper .ms-Toggle {
   margin:0;
}

.call-diag-wrapper .btn {
    margin-right:40px;
}
.ms-Toggle-background {
    border-radius: 10px !important;
}

.wizard-page-heading p {
    margin: 0.83em 0 0.83em 0;
}
.downloadBilling-button {
    position: relative;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0);
}
.downloadBilling-button .btn{
    background-color: rgba(0, 0, 0, 0);
    padding: 0 10px 0 0;
}
.downloadBilling-dropdown {
    display: none;
    position: relative;
    background-color: rgba(0,0,0,0);
    z-index: 1;
    top: 0;
}

.downloadBilling-dropdown .btn {
    margin-left: 0;
}

.downloadBilling-button-wrapper:hover .downloadBilling-dropdown, .downloadBilling-button-wrapper:active .downloadBilling-dropdown {
    display: inline-block;
}

.downloadBilling-dropdown:hover, .downloadBilling-dropdown:active {
    display: inline-block;
}

/* for Fluent UI selects to blend with others */
.select-standard .ms-Dropdown-title {
    border-color: rgb(204, 204, 204);
    border-radius: 0;
}

/* Fluent UI dropdown beyond modal */
.ms-Modal.is-open.allow-overflow-modal .ms-Dialog-main,
.ms-Modal.is-open.allow-overflow-modal .ms-Dialog-main .ms-Modal-scrollableContent {
    max-width: 600px;
    overflow: visible;
}

.ms-Toggle--faux-label {
    margin: 0;
    padding: 6px 0;
}
.push-top {
    margin-top: 20px;
}
span.failed-state-sync {
    color: maroon;
}

.user-form {
    position: relative;
}
.alert-full-form {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255,255,255,0.75);
    z-index: 50;
    font-size: 18px;
    margin: 0 !important;
    display: flex;
    align-items: center;
}
.alert-full-form p {
    width: 100%;
    display: block;
    text-align: center;
}

.disposition-selector {
    opacity: 1.0;
}

/* off-yellow for some visual separation */
.disposition-selector  > div {
    background:#ffffe8;
}

div.logo-container {
    border: solid 1px #aaa;
    display: inline-flex;
}

div.no-image {
    min-width: 200px;
    min-height: 200px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    color:#000000;
}

.img-logo {
    height: 100%;
    width: 100%;
}

.ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after {
    background: #aaa !important;
}

.ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
    background: #aaa !important;
}

.system-owner-container {
    background: rgba(241,196,15, 0.3);
    border-color: rgba(241,196,15, 0.3) !important;
}

.presence-status table tr:nth-child(even) {
    background: #F0F0F0
}
.branding-draft-warning {
    margin: 2rem 0;
}
.sbc-warning {
    padding: 1rem 0;
    margin-bottom:2rem;
}
.sbc-warning .title {
    padding: 1rem;
}
.sbc-warning-icon {
    font-size: x-large;
    padding: 0 5px;
    float: left;
}
.sbc-warning-header {
    color: red;
    font-weight: bold;
}
.sbc-warning-text {
    margin-left: 35px;
    padding-top: 10px;
}
.sbc-warning-subheading {
    padding: 1rem 0;
    font-weight: bold;
}
.sbc-warning-content ul {
    list-style: decimal;
}
.summary-p {
    margin: 10px 0;
}
.audit-history {
    margin: 2rem;
}
.audit-history .title{
    padding-bottom: 1rem;
}
.audit-history .old-value{
    color: red !important;
    border: none !important;
}
.audit-history .new-value{
    color: green !important;
    border: none !important;
}
.inner-audit-table{
    padding: 1rem;
    margin: 2.5rem;
}
.phone-app-warning-box {
    border: solid 1px #e93a3a;
    padding: 6px;
    background-color: rgb(233 58 58 / 15%);
}
.icon-green {
    color: #16b316;
}
.support-link {
    position: relative;
    display: inline-block;
}

.support-popup {
    display: none;
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #ddd;
    padding: 1rem;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    /* width: 260px; */
}

.support-popup div {
    white-space: nowrap;
    display: flex;
}

.support-popup div b {
    margin-right: .5rem;
}

.support-link:hover .support-popup {
    display: block;
}
.shortenedAccountName {
    padding-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 48px !important;
}
.account-tablecell{
    overflow: hidden;
    display:flex;
    flex-wrap: nowrap;
}
.accountName-tablecell{
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    float:left;
}
.deleteAccount-tablecell{
    display: inline-block;
    float:right;
}
.emergency-call {
    color: #F00;
}
.ReactTable {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.ReactTable * {
	box-sizing: border-box
}

.ReactTable .rt-table {
	-webkit-box-flex: 1;
	-ms-flex: auto 1;
	flex: auto 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
	border-collapse: collapse;
	overflow: auto;
    min-height: 110px;
    margin-bottom: 0 !important;
}

.ReactTable .rt-thead {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ReactTable .rt-thead.-headerGroups {
	background: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.05)
}

.ReactTable .rt-thead.-filters {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
	border: 1px solid rgba(0, 0, 0, 0.1);
	background: #fff;
	padding: 5px 7px;
	font-size: inherit;
	border-radius: 3px;
	font-weight: normal;
	outline-width: 0
}

.ReactTable .rt-thead.-filters .rt-th {
	border-right: 1px solid rgba(0, 0, 0, 0.02)
}

.ReactTable .rt-thead.-header {
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15)
}

.ReactTable .rt-thead .rt-tr {
	text-align: center
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
	padding: 5px 5px;
	line-height: normal;
	position: relative;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
	box-shadow: inset 0 0 0 0 transparent;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
	box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6)
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
	box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6)
}

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
	cursor: pointer
}

.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
	border-right: 0
}

.ReactTable .rt-thead .rt-th:focus {
	outline-width: 0
}

.ReactTable .rt-thead .rt-resizable-header {
	overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
	overflow: hidden
}

.ReactTable .rt-thead .rt-resizable-header-content {
	overflow: hidden;
	text-overflow: ellipsis
}

.ReactTable .rt-thead .rt-header-pivot {
	border-right-color: #f7f7f7
}

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none
}

.ReactTable .rt-thead .rt-header-pivot:after {
	border-color: rgba(255, 255, 255, 0);
	border-left-color: #fff;
	border-width: 8px;
	margin-top: -8px
}

.ReactTable .rt-thead .rt-header-pivot:before {
	border-color: rgba(102, 102, 102, 0);
	border-left-color: #f7f7f7;
	border-width: 10px;
	margin-top: -10px
}

.ReactTable .rt-tbody {
	-webkit-box-flex: 99999;
	-ms-flex: 99999 1 auto;
	flex: 99999 1 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	overflow: auto;
}

.ReactTable .rt-tbody .rt-tr-group {
	border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
	border-bottom: 0
}

.ReactTable .rt-tbody .rt-td {
	border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.ReactTable .rt-tbody .rt-td:last-child {
	border-right: 0
}

.ReactTable .rt-tbody .rt-expandable {
	cursor: pointer;
	text-overflow: clip
}

.ReactTable .rt-tr-group {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch
}

.ReactTable .rt-tr {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex
}

.ReactTable .rt-th,
.ReactTable .rt-td {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 0px;
	flex: 1 0 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 7px 5px;
	overflow: hidden;
	transition: .3s ease;
	transition-property: width, min-width, padding, opacity;
}

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
	width: 0 !important;
	min-width: 0 !important;
	padding: 0 !important;
	border: 0 !important;
	opacity: 0 !important
}

.ReactTable .rt-expander {
	display: inline-block;
	position: relative;
	margin: 0;
	color: transparent;
	padding: 0 10px;
    border: none;
    background: transparent;
}

.ReactTable .rt-expander:after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%) rotate(-90deg);
	transform: translate(-50%, -50%) rotate(-90deg);
	border-left: 5.04px solid transparent;
	border-right: 5.04px solid transparent;
	border-top: 7px solid rgba(0, 0, 0, 0.8);
	transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
	cursor: pointer
}

.ReactTable .rt-expander.-open:after {
	-webkit-transform: translate(-50%, -50%) rotate(0);
	transform: translate(-50%, -50%) rotate(0)
}

.ReactTable .rt-resizer {
	display: inline-block;
	position: absolute;
	width: 36px;
	top: 0;
	bottom: 0;
	right: -18px;
	cursor: col-resize;
	z-index: 10
}

.ReactTable .rt-tfoot {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-tfoot .rt-td {
	border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tfoot .rt-td:last-child {
	border-right: 0
}

.ReactTable.-striped .rt-tr.-odd {
	background: rgba(0, 0, 0, 0.03)
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
	background: rgba(0, 0, 0, 0.05)
}

.ReactTable .-pagination {
	z-index: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 3px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
	border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
	border: 1px solid rgba(0, 0, 0, 0.1);
	background: #fff;
	padding: 5px 7px;
	font-size: inherit;
	border-radius: 3px;
	font-weight: normal;
	outline-width: 0
}

.ReactTable .-pagination .-btn {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	display: block;
	width: 100%;
	height: 100%;
	border: 0;
	border-radius: 3px;
	padding: 6px;
	font-size: 1em;
	color: rgba(0, 0, 0, 0.6);
	background: rgba(0, 0, 0, 0.1);
	transition: all .1s ease;
	cursor: pointer;
	outline-width: 0;
}

.ReactTable .-pagination .-btn[disabled] {
	opacity: .5;
	cursor: default
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
	background: rgba(0, 0, 0, 0.3);
	color: #fff
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	text-align: center
}

.ReactTable .-pagination .-center {
	-webkit-box-flex: 1.5;
	-ms-flex: 1.5;
	flex: 1.5;
	text-align: center;
	margin-bottom: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: distribute;
	justify-content: space-around
}

.ReactTable .-pagination .-pageInfo {
	display: inline-block;
	margin: 3px 10px;
	white-space: nowrap
}

.ReactTable .-pagination .-pageJump {
	display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
	width: 70px;
	text-align: center
}

.ReactTable .-pagination .-pageSizeOptions {
	margin: 3px 10px
}

.ReactTable .rt-noData {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background: rgba(255, 255, 255, 0.8);
	transition: all .3s ease;
	z-index: 1;
	pointer-events: none;
	padding: 20px;
	color: rgba(0, 0, 0, 0.5)
}

.ReactTable .-loading {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.8);
	transition: all .3s ease;
	z-index: 1;
	opacity: 0.9;
	pointer-events: none;
}

.ReactTable .-loading>div {
	position: absolute;
	display: block;
	text-align: center;
	width: 100%;
	top: 50%;
	left: 0;
	font-size: 15px;
	color: rgba(0, 0, 0, 0.6);
	-webkit-transform: translateY(-52%);
	transform: translateY(-52%);
	transition: all .3s cubic-bezier(.25, .46, .45, .94)
}

.ReactTable .-loading.-active {
	opacity: 1;
	z-index: 2;
	pointer-events: all;
}

.ReactTable .-loading.-active>div {
	-webkit-transform: translateY(50%);
	transform: translateY(50%)
}

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
	transition: none !important;
	cursor: col-resize;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}